<template>
  <div>
    <director_app_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-flow"
        :title="
          'ข้อมูลเลขที่ตำแหน่งว่าง รายงานตัวเลือกสถานศึกษา ตำแหน่ง รองผู้อำนวยการ รอบที่ : ' +
            (period_sedirectors.period_sedirector_times || '-') +
            ' / ' +
            (period_sedirectors.period_sedirector_year || '-')
        "
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" md="8" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" md="4" class="text-right">
              <v-btn
                block
                right
                depressed
                color="primary"
                @click.native="director_college_manpowerAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>

              <v-btn
                block
                right
                depressed
                color="primary"
                @click.native="director_college_manpowercsv()"
              >
                <v-icon>mdi-file-import</v-icon>นำเข้าข้อมูลอัตรา
              </v-btn>

              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="director_college_manpowerTotal()"
              >
                <v-icon>mdi-clipboard-text</v-icon>สรุปจำนวนเรียกรายงานตัว
              </v-btn>
              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="director_college_manpowerQueryAll()"
              >
                <v-icon>mdi-clipboard-text</v-icon>แสดงข้อมูลทั้งหมด
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="director_college_manpowers"
          :search="search"
          group-by="region_name"
          class="elevation-1"
          :item-class="row_classes"
          disable-pagination
        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="10">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].region_name }}
            </th>
          </template>

          <template v-slot:item.whoBooking="{ item }">
            <span>{{ item.whoBooking }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="
                item.sedirector_app_status_report !== 'send' &&
                  item.sedirector_app_status_report !== 'receipt'
              "
              color="warning"
              @click.stop="
                director_college_manpowerEdit(
                  item.dcm_times,
                  item.dcm_years,
                  item.dcm_idpositons
                )
              "
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              v-if="
                item.sedirector_app_status_report !== 'send' &&
                  item.sedirector_app_status_report !== 'receipt'
              "
              color="red"
              @click.stop="
                director_college_manpowerDelete(
                  item.dcm_times,
                  item.dcm_years,
                  item.dcm_idpositons
                )
              "
            >
              mdi-delete
            </v-icon>
            <v-icon v-else dark color="warning">mdi-cube-send</v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--adddirector_college_manpowerdialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="adddirector_college_manpowerdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              :title="
                'เพิ่มข้อมูลเลขที่ตำแหน่งวิทยาลัยใช้รายงานตัว รอบที่ : ' +
                  period_sedirectors.period_sedirector_times +
                  ' / ' +
                  period_sedirectors.period_sedirector_year
              "
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="adddirector_college_manpowerform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md4>
                      <v-autocomplete
                        :items="colleges"
                        item-text="college_name"
                        item-value="college_code"
                        outlined
                        label="วิทยาลัย"
                        v-model="adddirector_college_manpower.dcm_college_code"
                        required
                        :rules="[v => !!v || '']"
                        @change="jor18Query(), CollegeMPQuery()"
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="jor18s"
                        item-text="id_position"
                        item-value="id_position"
                        outlined
                        label="เลขที่ตำแหน่ง"
                        v-model="adddirector_college_manpower.dcm_idpositons"
                        required
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:item="{ item }">
                          <div class="pa-2">
                            <div class="font-weight-black">
                              {{ item.college }}
                            </div>

                            <div class="divTab50">
                              {{ item.id_position }} :
                              {{ item.id_card || "ว่าง" }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md12>
                      <v-data-table
                        color="success"
                        :loading="loading"
                        :headers="headersCollegeBranch"
                        :items="CollegeMPs"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            color="red"
                            @click.stop="
                              director_college_manpowerDelete(
                                item.dcm_times,
                                item.dcm_years,
                                item.dcm_idpositons
                              )
                            "
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="warning"
                large
                @click.stop="adddirector_college_manpowerdialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="adddirector_college_manpowerSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletedirector_college_manpowerdialog -->
      <v-layout>
        <v-dialog
          v-model="deletedirector_college_manpowerdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบรายการเลขที่ตำแหน่ง รับรายงานตัว"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form
                    ref="deletedirector_college_manpowerform"
                    lazy-validation
                  >
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูล <br />
                          {{
                            editdirector_college_manpower.dcm_times +
                              "/" +
                              editdirector_college_manpower.dcm_years +
                              " : " +
                              editdirector_college_manpower.college_name +
                              " เลขที่ตำแหน่ง " +
                              editdirector_college_manpower.dcm_idpositons
                          }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="deletedirector_college_manpowerdialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletedirector_college_manpowerSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editdirector_college_manpowerdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editdirector_college_manpowerdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูล เลขที่ตำแหน่งรายงานตัว"
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editdirector_college_manpowerform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md4>
                      <h3>{{ editdirector_college_manpower.college_name }}</h3>
                    </v-flex>
                    <v-flex md3>
                      <h3>
                        เลขที่ตำแหน่ง :
                        {{ editdirector_college_manpower.dcm_idpositons }}
                      </h3>
                    </v-flex>

                    <v-flex md12>
                      <v-data-table
                        color="success"
                        :loading="loading"
                        :headers="headersCollegeBranch"
                        :items="CollegeMPs"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            color="info"
                            @click.stop="
                              mamCollegeQuery(
                                item.dcm_times,
                                item.dcm_years,
                                item.dcm_college_code
                              )
                            "
                          >
                            mdi-account-search
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editdirector_college_manpowerdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editdirector_college_manpowerSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model director_college_manpowercsvdialog -->
      <v-layout>
        <v-dialog
          v-model="director_college_manpowercsvdialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลเลขที่ตำแหน่ง"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form
                    ref="director_college_manpowercsvdialogform"
                    lazy-validation
                  >
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-btn
                            outlined
                            block
                            large
                            color="info"
                            to="/admin/director_college_manpower_import/"
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกแบบฟอร์มบันทึกข้อมูล</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <h2>
                            <v-file-input
                              v-model="file2"
                              accept=".csv"
                              name="csvFiles"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์ .csv"
                              placeholder="ไฟล์ CSV นำเข้าข้อมูลเลขที่ตำแหน่งว่าง รองผู้อำนวยการ"
                              prepend-icon="mdi-paperclip"
                              outlined
                              rounded
                              :show-size="1000"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </h2>

                          <v-btn
                            class="pa-2"
                            block
                            large
                            color="green"
                            @click.stop="csvManpowerSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="director_college_manpowercsvdialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model director_college_manpowerCountDialog -->
      <v-layout>
        <v-dialog
          v-model="director_college_manpowerCountDialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="ข้อมูลสรุปเลขที่ตำแหน่ง รับรายงานตัว"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                  single-line
                  hide-details
                  dense
                  filled
                  class="mb-2"
                />

                <v-data-table
                  color="success"
                  :loading="loading"
                  :headers="headersCount"
                  :items="director_college_manpowerCount"
                  :search="search"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      color="info"
                      @click.stop="
                        mamCollegeQuery(
                          item.dcm_times,
                          item.dcm_years,
                          item.dcm_college_code
                        )
                      "
                    >
                      mdi-account-search
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="director_college_manpowerCountDialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import director_app_bar from "../../../components/admin/director/director_app_bar";

export default {
  name: "HRvecManageAssistantTeacher",
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      file2: null,
      adddirector_college_manpowerdialog: false,
      editdirector_college_manpowerdialog: false,
      deletedirector_college_manpowerdialog: false,
      director_college_manpowercsvdialog: false,
      director_college_manpowerCountDialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      director_college_manpowers: [],
      adddirector_college_manpower: {},
      editdirector_college_manpower: {},
      userstatus: [],
      search: "",
      pagination: {},
      headers: [
        { text: "รอบที่", align: "left", value: "dcm_times" },
        { text: "ปี", align: "left", value: "dcm_years" },
        { text: "ภาค", align: "left", value: "region_name" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "เลขที่ตำแหน่ง", align: "left", value: "dcm_idpositons" },
        { text: "สถานะ", align: "left", value: "whoBooking" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      college: {},
      provinces: [],
      prefectures: [],
      collgegs: [],
      director_college_manpowerstatus: [],
      regions: [],
      region_ena: true,
      data_syslog: {},

      period_sedirectors: [],
      headersCount: [
        { text: "รอบที่", align: "left", value: "dcm_times" },
        { text: "ปี", align: "left", value: "dcm_years" },
        { text: "รหัส", align: "left", value: "dcm_college_code" },
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "จำนวน", align: "left", value: "collegeGroup" },
        { text: "แสดง", align: "left", value: "actions" }
      ],
      headersCollegeBranch: [
        { text: "รอบที่", align: "left", value: "dcm_times" },
        { text: "ปี", align: "left", value: "dcm_years" },
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "เลขที่ตำแหน่ง", align: "left", value: "dcm_idpositons" },
        {
          text: "ลบ",
          align: "center",
          value: "actions",
          icon: "mdi-delete-forever"
        }
      ],
      director_college_manpowerCount: [],
      colleges: [],
      jor18s: [],
      CollegeMPs: []
    };
  },
  async mounted() {
    await this.period_sedirectorQuery();
  },
  methods: {
    async collegeQuery() {
      let result = await this.$http.post("college.php", {
        ApiKey: this.ApiKey
      });
      this.colleges = result.data;
    },
    async jor18Query() {
      let result = await this.$http.post("jor18.php", {
        ApiKey: this.ApiKey,
        j18college_code: this.adddirector_college_manpower.dcm_college_code
      });
      this.jor18s = result.data;
    },
    async mamCollegeQuery(dcm_times, dcm_years, dcm_college_code) {
      let result = await this.$http.post("director_college_manpower.php", {
        ApiKey: this.ApiKey,
        dcm_times: dcm_times,
        dcm_years: dcm_years,
        dcm_college_code: dcm_college_code
      });
      this.director_college_manpowers = result.data;
      this.director_college_manpowerCountDialog = false;
    },
    async CollegeMPQuery() {
      let result = await this.$http.post("director_college_manpower.php", {
        ApiKey: this.ApiKey,
        dcm_times: this.period_sedirectors.period_sedirector_times,
        dcm_years: this.period_sedirectors.period_sedirector_year,
        dcm_college_code: this.adddirector_college_manpower.dcm_college_code
      });
      this.CollegeMPs = result.data;
    },
    async mcmCountQuery() {
      let result = await this.$http.post("director_college_manpower.php", {
        ApiKey: this.ApiKey,
        dcm_times: this.period_sedirectors.period_sedirector_times,
        dcm_years: this.period_sedirectors.period_sedirector_year,
        mcm_count: "Ok"
      });
      this.director_college_manpowerCount = result.data;
    },
    async period_sedirectorQuery() {
      let result = await this.$http.post("period_sedirector.php", {
        ApiKey: this.ApiKey,
        period_sedirector_id: "206",
        period_sedirector_process: "1"
      });
      this.period_sedirectors = result.data;
      if (this.period_sedirectors.period_sedirector_process === "1") {
        await this.mcmCountQuery();
        await this.director_college_manpowerQueryAll();
        await this.collegeQuery();
      } else {
      }
    },

    async csvManpowerSubmit() {
      let result = "";
      let uploaded = null;
      if (this.file2) {
        let formData = new FormData();
        let filename = this.time_stamp + ".manpower.csv";
        formData.append("file", this.file2);
        formData.append("filename", "../HRvecfiles/" + filename);
        formData.append("ApiKey", this.ApiKey);
        result = await this.$http.post(
          "director_college_manpowercsv.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
        uploaded = true;
      } else {
        uploaded = false;
      }
      
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.director_college_manpowerQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
        this.director_college_manpowerQueryAll();
      }
      this.director_college_manpowercsvdialog = false;
    },
    async director_college_manpowercsv() {
      this.director_college_manpowercsvdialog = true;
    },
    async director_college_manpowerTotal() {
      await this.mcmCountQuery();
      this.director_college_manpowerCountDialog = true;
    },
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },
    async director_college_manpowerReplace() {
      this.loading = true;
      let result = await this.$http
        .post("director_college_manpower.php", {
          ApiKey: this.ApiKey,
          director_college_manpowerCheck: "Ok"
        })
        .finally(() => (this.loading = false));
      this.director_college_manpowers = result.data;
    },
    async director_college_manpowerQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("director_college_manpower.php", {
          ApiKey: this.ApiKey,
          dcm_times: this.period_sedirectors.period_sedirector_times,
          dcm_years: this.period_sedirectors.period_sedirector_year
        })
        .finally(() => (this.loading = false));
      this.director_college_manpowers = result.data;
    },
    async director_college_manpowerBranchIDQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("director_college_manpower.php", {
          ApiKey: this.ApiKey,
          mcm_id_branch: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.director_college_manpowers = result.data;
    },
    async director_college_manpowerAdd() {
      this.adddirector_college_manpower = {};
      this.CollegeMPs = [];
      this.adddirector_college_manpowerdialog = true;
    },
    async adddirector_college_manpowerSubmit() {
      if (this.$refs.adddirector_college_manpowerform.validate()) {
        this.adddirector_college_manpower.ApiKey = this.ApiKey;
        this.adddirector_college_manpower.dcm_times = this.period_sedirectors.period_sedirector_times;
        this.adddirector_college_manpower.dcm_years = this.period_sedirectors.period_sedirector_year;
        let result = await this.$http.post(
          "director_college_manpower.insert.php",
          this.adddirector_college_manpower
        );
        if (result.data.status == true) {
          this.director_college_manpower = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการบันทึกข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.director_college_manpowerQueryAll();
        this.CollegeMPQuery();
      }
    },
    async director_college_manpowerEdit(dcm_times, dcm_years, dcm_idpositons) {
      let result = await this.$http.post("director_college_manpower.php", {
        ApiKey: this.ApiKey,
        dcm_times: dcm_times,
        dcm_years: dcm_years,
        dcm_idpositons: dcm_idpositons
      });
      this.editdirector_college_manpower = result.data;
      
      let resultmcm = await this.$http.post("director_college_manpower.php", {
        ApiKey: this.ApiKey,
        dcm_times: this.editdirector_college_manpower.dcm_times,
        dcm_years: this.editdirector_college_manpower.dcm_years,
        dcm_college_code: this.editdirector_college_manpower.dcm_college_code
      });
      this.CollegeMPs = resultmcm.data;
      this.editdirector_college_manpowerdialog = true;
    },
    async editdirector_college_manpowerSubmit() {
      if (this.$refs.editdirector_college_manpowerform.validate()) {
        this.editdirector_college_manpower.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "director_college_manpower.update.php",
          this.editdirector_college_manpower
        );
        if (result.data.status == true) {
          this.director_college_manpower = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.director_college_manpowerQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        let resultmcm = await this.$http.post("director_college_manpower.php", {
          ApiKey: this.ApiKey,
          dcm_times: this.editdirector_college_manpower.dcm_times,
          dcm_years: this.editdirector_college_manpower.dcm_years,
          dcm_college_code: this.editdirector_college_manpower.dcm_college_code
        });
        this.CollegeMPs = resultmcm.data;
      }
    },
    async director_college_manpowerDelete(
      dcm_times,
      dcm_years,
      dcm_idpositons
    ) {
      let result = await this.$http.post("director_college_manpower.php", {
        ApiKey: this.ApiKey,
        dcm_times: dcm_times,
        dcm_years: dcm_years,
        dcm_idpositons: dcm_idpositons
      });
      this.editdirector_college_manpower = result.data;
      this.deletedirector_college_manpowerdialog = true;
    },
    async deletedirector_college_manpowerSubmit() {
      if (this.$refs.deletedirector_college_manpowerform.validate()) {
        this.editdirector_college_manpower.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "director_college_manpower.delete.php",
          this.editdirector_college_manpower
        );
        if (result.data.status == true) {
          this.director_college_manpower = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.director_college_manpowerQueryAll();
        this.deletedirector_college_manpowerdialog = false;
        this.CollegeMPQuery();
      }
    },
    row_classes(item) {
      if (item.dcm_id_card_booking) {
        return "green";
      }
    }
  },
  computed: {
    color() {
      return "light-blue-darken-4";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  },
  components: { director_app_bar }
};
</script>

<style lang="scss" scoped></style>
